import React from "react";
import { Link } from "react-router-dom";

const PricingTable = ({ title, price, features }) => {
  return (
    <div className="col-md-4">
      <div className="pricingTable pricingTable-2">
        <div className="pricingTable-header">
          <h3 className="title">{title}</h3>
          <h5 className="price-month">Per Month</h5>
          <h1 className="price-value">
            {price.value}{" "}
            <span className="value-bg">{price.currency}{price.amount}</span>
          </h1>
        </div>
        <ul className="pricing-content">
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
        <Link to="https://admin.smartschoolshub.com/register" className="btn btn-lg btn-price-bg">
          Sign Up
        </Link>
      </div>
    </div>
  );
};

const PricingSection = () => {
  const pricingData = [
    {
      title: "Standard",
      price: {
        currency: "₦",
        value: "45,000",
        amount: "45,000 Termly"
      },
      features: ["0-200 Students", "25 Teachers", "Web & mobile App", "Attendance Management", "Students Result Mgt", "Library & Support"]
    },
    {
      title: "Business",
      price: {
        currency: "₦",
        value: "75,000 ",
        amount: "75,000"
      },
      features: ["0-400 Students", "50 Teachers", "Web & mobile App", "Attendance Management", "Students Result Mgt", "CBT Exam", "Accounting Mgt", "Educational Materials", "Library & Support"]
    },
    {
      title: "Premium",
      price: {
        currency: "₦",
        value: "96000",
        amount: "96000"
      },
      features: ["Unlimited Students & Teachers", "Web & mobile App", "Attendance Management", "Students Result Mgt", "CBT Exam", "Accounting Mgt", "Quiz/Assignments", "Educational Materials", "Asset Management/Inventory", "Administrative Report", "Library & Support"]
    }
  ];

  return (
    <div>
      <section id="pricing" className="our_pricing section-padding">
        <div className="container">
          <div className="row">
            {pricingData.map((pricing, index) => (
              <PricingTable key={index} {...pricing} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default PricingSection;