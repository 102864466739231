import React from "react";

import PricingPart from "./PricingSection";

const PricingMain = () => {
  return (
    <>
      <PricingPart />
    </>
  );
};

export default PricingMain;
