import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer/FooterTwo";
import Breadcrumb from "../../components/Breadcrumb";
import ScrollToTop from "../../components/ScrollTop";

import Logo from "../../assets/images/logos/logo2.png";
import PricingMain from "./PricingMain";

const Course = () => {
  return (
    <body class="courses-grid-page">
      <Header
        parentMenu="pricing"
        menuCategoryEnable="enable"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
      />

      <div class="react-wrapper">
        <div class="react-wrapper-inner">
          <Breadcrumb pageTitle="Pricing" />

          <PricingMain />
          {/* scrolltop-start */}
          <ScrollToTop />
          {/* scrolltop-end */}
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default Course;
