import React from 'react';
import { Link } from 'react-router-dom';

// Image
import aboutImg from '../../assets/images/about/ab3.png';
import shapeImg1 from '../../assets/images/about/shape_02.png';

const About = () => {

    return (
        <div class="about__area about2__area about3__area p-relative pb---60">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            <img class="react__shape__1" src={shapeImg1} alt="Shape Image" />
                            <img src={aboutImg} alt="About" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about__content">
                            <h6 className="wow animate__fadeInUp" data-wow-duration="0.3s">Our Story</h6>
                            <h2 class="about__title wow animate__fadeInUp" data-wow-duration="0.5s">Unlocking Learning Potential</h2>
                            <p class="about__paragraph wow animate__fadeInUp" data-wow-duration="0.7s">Discover the most effective way to learn with our innovative platform. We're dedicated to providing a seamless learning experience that empowers individuals and organizations to achieve their goals.</p>
                            <ul>
                                <li className="wow animate__fadeInUp" data-wow-duration="0.3s"><i class="icon_check"></i> Explore online courses</li>
                                <li className="wow animate__fadeInUp" data-wow-duration="0.5s"><i class="icon_check"></i> Elevate your organization's skills</li>
                            </ul>
                            <div class="about__btn wow animate__fadeInUp" data-wow-duration="0.7s">
                                <Link to="/about"> Learn More </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
