import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";

import shape1 from "../../assets/images/hero/04.png";
import shape2 from "../../assets/images/hero/05.png";
import shape3 from "../../assets/images/hero/shape_03.png";
import shape4 from "../../assets/images/hero/shape_04.png";
import shape5 from "../../assets/images/hero/shape_05.png";

import bannerImg1 from "../../assets/images/hero/02.png";
import bannerImg2 from "../../assets/images/hero/03.png";

const HomeBanner = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);

  return (
    <>
      <div className="hero4__area pt-160 pb-150 md-pt-90">
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="e5Hc2B50Z7c"
          onClose={() => {
            openModal();
          }}
        />
        <div className="hero4__shape">
          <img
            className="hero4__shape-1"
            src={shape1}
            alt="Banner shape image"
          />
          <img
            className="hero4__shape-2"
            src={shape2}
            alt="Banner shape image"
          />
          <img
            className="hero4__shape-3"
            src={shape3}
            alt="Banner shape image"
          />
          <img
            className="hero4__shape-4"
            src={shape4}
            alt="Banner shape image"
          />
          <img
            className="hero4__shape-5"
            src={shape5}
            alt="Banner shape image"
          />
        </div>
        <div className="container hero4__width">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="hero4__content">
                <h1
                  className="hero4__title wow animate__fadeInUp"
                  data-wow-duration="0.3s"
                >
                  Making Education Better for Everyone
                </h1>
                <p
                  className="hero4__paragraphs wow animate__fadeInUp"
                  data-wow-duration="0.5s"
                >
                  Empowering education for all, our school management app
                  supports learners, educators, and administrators. From local
                  schools to prestigious institutions, we streamline
                  communication, administration, and learning. Join us in
                  revolutionizing education!
                </p>
                <div className="event__video-btn--plays">
                  <Link
                    to="https://admin.smartschoolshub.com"
                    className="hero4-btn wow animate__fadeInUp"
                    data-wow-duration="0.7s"
                  >
                    Login
                  </Link>
                  <Link
                    to="#"
                    onClick={() => {
                      openModal();
                    }}
                    className="event__videos custom-popup wow animate__fadeInUp"
                    data-wow-duration="0.9s"
                  >
                    <i className="arrow_triangle-right"></i>
                    <em>See Demo</em>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="hero4__image">
                <img
                  className="hero4__image-1"
                  src={bannerImg1}
                  alt="The girl are pointing to the left side"
                />
                <img
                  className="hero4__image-2"
                  src={bannerImg2}
                  alt="The boy are grabbing some books"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBanner;
